<template>
  <div class="box" style="background: linear-gradient(180deg, #00B5A0 0%, #0C9793 100%);">
    <div class="top-box">
          <div @click="selPic">
              <p v-if="myInfo!=null">Hi，{{myInfo.types == '1' ? '乘客': '车主'}}{{myInfo.name}}</p>
              <div class="riqi">
                  <img style="width:18px;height:18px;" src="https://middle-ground.shulanhealth.com/rili-icon%402x.png" alt="">
                  <span v-if="weatherInfo!=null">今天是 {{toDay}}，天气{{weatherInfo.weather}}，气温{{weatherInfo.temperature}}°</span>
              </div>
          </div>
          <!-- <img class="head-sty" :src="'https://wx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTL4Mluv3Vq2pwFZVfbeYUelic7xMjAheDCsQcvGVhG3lg6TpRrEZA7fQJzw2FduyWIGnBYFfbJq7nA/132'" alt=""> -->
      </div>
      <!-- <div class="dcx" v-if="myTx != null" @click="showPassengerInfo">你有1个行程</div> -->
    <div class="bar-box" style="margin-top:20px;">
      <div class="bar-item" @click="goTo" style="border-top-left-radius: 15px;">
        <img
          :src="'https://middle-ground.shulanhealth.com/jkgl/img-08.png'"
          alt="img"
        />
        <span v-if="myInfo!=null">{{myInfo.types == '1' ? '乘客': '车主'}}发布</span>
      </div>
      <div class="bar-item" @click="goMy" style="border-top-right-radius: 15px;">
        <img
          :src="'https://middle-ground.shulanhealth.com/jkgl/img-10.png'"
          alt="img"
        />
        <span>我的</span>
      </div>
    </div>
    <van-tree-select
      height="calc(100vh - 125px)"
      :items="items"
      :main-active-index.sync="active"
      @click-nav="searchXc"
    >
      <template #content>
        <div v-if="myInfoType == '2'">
          <div style="margin-top: 20px; padding-bottom: 15px">
          <div class="list-item" v-for="item in sfcList" :key="item.id">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <div>
                <div style="font-size: 16px; font-weight: 600">
                  {{ item.islong == 1 ? time2(item.tripDate) : '每天' }} {{ item.tripTime }}
                </div>
                <div>
                  <span style="margin-right: 5px"
                    ><van-tag type="primary"
                      >{{ item.passengerNum }}人</van-tag
                    ></span
                  >
                  <span style="margin-right: 5px"
                    ><van-tag type="success">{{
                      item.carpooling == 1 ? "愿拼座" : "不拼座"
                    }}</van-tag></span
                  >
                  <span style="margin-right: 5px"
                    ><van-tag type="danger">{{
                      item.luggage == 1 ? "无大件行李" : "有大件行李"
                    }}</van-tag></span
                  >
                  <span
                    ><van-tag type="warning">{{ item.islong == 1 ? '单次行程' : '长期行程' }}</van-tag></span
                  >
                </div>
              </div>
              <div
                style="font-size: 22px; font-weight: 500"
                v-if="item.fee != null"
              >
                {{ item.fee
                }}<span style="font-size: 12px; margin-left: 2px">元</span>
              </div>
              <div style="font-size: 18px; font-weight: 600" v-else>面议</div>
            </div>
            <div
              style="
                margin-top: 15px;
                padding-bottom: 10px;
                border-bottom: 1px solid #f2f2f2;
              "
            >
              <div style="display: flex; font-size: 12px">
                <div>
                  <van-icon
                    name="location-o"
                    style="font-size: 14px; margin-right: 3px"
                  />起点：
                </div>
                <div>{{ item.startAddress }}</div>
              </div>
              <div
                style="
                  width: 95%;
                  margin-left: 5%;
                  height: 1px;
                  /* background: #f2f2f2; */
                  margin-top: 5px;
                "
              ></div>
              <div style="display: flex; font-size: 12px; margin-top: 5px">
                <div>
                  <van-icon
                    name="location-o"
                    style="font-size: 14px; margin-right: 3px"
                  />终点：
                </div>
                <div>{{ item.endAddress }}</div>
              </div>
            </div>
            <div
              style="
                width: 100%;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-top: 10px;
              "
            >
            <div class="cklx" style="margin-right:10px;" @click="fengxiang(item)">分享行程</div>
            <div class="cklx" @click="showTrip(item)">查看路线</div>
              <!-- <div class="cklx">立即同行</div> -->
            </div>
          </div>
        </div>
        <empty :msg="'该区域暂无行程'" :show="sfcList.length==0 ? true : false"></empty>
        </div>
        <div v-else>
          <div style="margin-top: 20px; padding-bottom: 15px">
          <div class="list-item" v-for="item in sfcList" :key="item.id">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <div>
                <div style="font-size: 16px; font-weight: 600">
                  {{ item.islong == 1 ? time2(item.tripDate) : '每天' }} {{ item.tripTime }}
                </div>
                <div>
                  <span style="margin-right: 5px"
                    ><van-tag type="primary"
                      >可坐{{ item.passengerNum }}人</van-tag
                    ></span
                  >
                  <span style="margin-right: 5px"
                    ><van-tag type="success">{{
                      formatCarNo(item.users.car_no)
                    }}</van-tag></span
                  >
                  <span style="margin-right: 5px"
                    ><van-tag type="warning">{{ item.islong == 1 ? '单次行程' : '长期行程' }}</van-tag></span
                  >
                </div>
              </div>
              <div
                style="font-size: 22px; font-weight: 500"
                v-if="item.fee != null"
              >
                {{ item.fee
                }}<span style="font-size: 12px; margin-left: 2px">元</span>
              </div>
              <div style="font-size: 18px; font-weight: 600" v-else>面议</div>
            </div>
            <div
              style="
                margin-top: 15px;
                padding-bottom: 10px;
                border-bottom: 1px solid #f2f2f2;
              "
            >
              <div style="display: flex; font-size: 12px">
                <div>
                  <van-icon
                    name="location-o"
                    style="font-size: 14px; margin-right: 3px"
                  />起点：
                </div>
                <div>{{ item.startAddress }}</div>
              </div>
              <div style="display: flex; font-size: 12px; margin-top: 5px" v-if="item.tjd!=null">
                <div>
                  <van-icon
                    name="location-o"
                    style="font-size: 14px; margin-right: 3px"
                  />途经：
                </div>
                <div style="display:flex;flex-direction: column;"><div  v-for="(ite,index) in JSON.parse(item.tjd)" :key="index">{{ ite.name }}</div></div>
              </div>
              <div style="display: flex; font-size: 12px; margin-top: 5px">
                <div>
                  <van-icon
                    name="location-o"
                    style="font-size: 14px; margin-right: 3px"
                  />终点：
                </div>
                <div>{{ item.endAddress }}</div>
              </div>
            </div>
            <div
              style="
                width: 100%;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-top: 10px; ;
              "
            >
              <div class="cklx" style="margin-right:10px;" @click="fengxiang(item)">分享行程</div>
              <div class="cklx" @click="showTrip(item)">查看路线</div>
              <!-- <div class="cklx">立即同行</div> -->
            </div>
          </div>
        </div>
        <empty :msg="'该区域暂无行程'" :show="sfcList.length==0 ? true : false"></empty>
        </div>
      </template>
    </van-tree-select>
    <loading :show="isShowLoading"></loading>
    <van-popup v-model="showTishi" position="top">
            <div style="width: 100%; text-align: right">
                <img
                    style="width: 55%"
                    src="./comm/tishi.png"
                    alt=""
                    @click="closeTiShi"
                />
            </div>
        </van-popup>
  </div>
</template>
<script>
import { Tag, TreeSelect,Toast,Popup,Dialog } from "vant";
import dayjs from 'dayjs'
import AMap from "AMap"; // 引入高德地图
import Empty from "./comm/empty.vue";
import Loading from "./comm/loading.vue";
import { passenger } from "@/api";
const wx = window.wx;
export default {
  components: {
    Empty: Empty,
    Loading: Loading,
    VanTag: Tag,
    VanTreeSelect: TreeSelect,
    VanPopup: Popup
  },
  data() {
    return {
      activeId: "",
      active: 0,
      opacitys: 0,
      opacitys1: 0,
      showTishi: false,
      items: [
        { text: "上城区"},
        { text: "下城区"},
        { text: "江干区"},
        { text: "拱墅区"},
        { text: "西湖区"},
        { text: "滨江区"},
        { text: "萧山区"},
        { text: "余杭区"},
        { text: "临平区"},
        { text: "富阳区"},
        { text: "临安区"},
      ],
      isShowLoading: true,
      value: 0,
      switch1: false,
      switch2: false,
      option: [
        { text: "全部区域", value: 0 },
        { text: "西湖区", value: 1 },
        { text: "滨江区", value: 2 },
      ],
      sfcList: [],
      myInfoType: null,
      myInfo: null,
      toDay: "",
      weatherInfo: null,
      myTx: null
    };
  },

  mounted() {
    // //挂载成功后给pop事件绑定一个方法
    // // 如果支持 popstate （一般移动端都支持）
    // if (window.history && window.history.pushState) {
    //   // 往历史记录里面添加一条新的当前页面的url
    //   history.pushState(null, null, document.URL);
    //   // 给 popstate 绑定一个方法用来监听页面返回
    //   window.addEventListener('popstate', () => {
    //     wx.closeWindow();
    //   }, false); //false阻止默认事件
    // }
    document.title = "行程";
    this.toDay = dayjs().format('YYYY年MM月DD日')
    this.myInfoType = JSON.parse(localStorage.getItem("user")).types
    this.myInfo = JSON.parse(localStorage.getItem("user"))
    console.log(this.myInfo)
    this.getwxSign()
    this.getPassengerTripDistrictList()
    this.getPassengerTripList();
    //加载天气查询插件
    let _this = this
    AMap.plugin('AMap.Weather', function() {
        //创建天气查询实例
        var weather = new AMap.Weather();

        //执行实时天气信息查询
        weather.getLive('杭州市', function(err, data) {
            console.log(err, data);
            _this.weatherInfo = data
        });
    });
    // console.log(this.dayjs().startOf('date'))
  },
  methods: {
    closeTiShi() {
      this.showTishi = false
    },
    fengxiang(e) {
      let _this = this
      if(this.myInfo.types == '2' && this.myInfo.status == '2') {
        Dialog.alert({
            title: '认证提醒',
            message: '您的车主审核尚未通过，请耐心等待',
        }).then(() => {
        // on close
            // this.getUserinfoAscy()
        });
        return
      }
      wx.showMenuItems({
        menuList: ["menuItem:share:appMessage"], // 要显示的菜单项，所有 menu 项见附录3
      });
      this.showTishi = true
      // wx.showMenuItems({
      //   menuList: ["menuItem:share:appMessage"], // 要显示的菜单项，所有 menu 项见附录3
      // });
      setTimeout(() => {
        let urls = `https://blog.qiyasoft.top/login?resouce=info&id=${e.id}`
        wx.updateAppMessageShareData({
              title: `${e.islong == 1 ? _this.getformat(e.tripDate) : '每天'} ${e.tripTime}`, // 分享标题
              desc: `${e.types == '1' ? '乘客' : '车主'}发布了${e.startAddress}-${e.endAddress}的行程，点击查看`, // 分享描述
              // link: `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf1e471795a1fd4e4&redirect_uri=${urls}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
              link: urls,
              imgUrl: "https://middle-ground.shulanhealth.com/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20221025083427.jpg", // 分享图标
              success: function (res) {
                  this.showTishi = false;
                  if (res.err_msg == "shareWechatMessage:ok") {
                      Toast.success("分享成功");
                  }
              },
          });
      }, 1000);           
    },
    getformat(e) {
      return dayjs(e).format('MM月DD日')
    },
    selPic() {
        wx.chooseImage({
            count: 3, // 默认9
            sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
            sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
            success: function (res) {
                var localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
                console.log(localIds)
            }
        });
    },
    getwxSign() {
            let href =  encodeURIComponent(window.location.href.split('#')[0])
            // let signLink = /(Android)/i.test(navigator.userAgent) ? href : window.entryUrl
            let params = {
              url: href,
            }
            passenger.getWxSign(params).then(res => {
              if(res.data.code == 200) {
                wx.config({
                        //beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                        debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: "wxf1e471795a1fd4e4", // 自己的：wxf1e471795a1fd4e4，sih: wxa77ba17b66c5cad5
                        timestamp: res.data.data.timestamp, // 
                        nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
                        signature: res.data.data.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
                        jsApiList: [
                          "onMenuShareTimeline",
                          "onMenuShareAppMessage",
                          "updateAppMessageShareData", //1.4 分享到朋友
                          "updateTimelineShareData", //1.4分享到朋友圈
                          "onMenuShareQQ",
                          "onMenuShareWeibo",
                          "onMenuShareQZone",
                          "hideMenuItems",
                          "showMenuItems",
                          "chooseImage",
                        ], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
                    });
                    wx.ready(function () {
                      wx.hideMenuItems({
                            menuList: [
                                "menuItem:share:appMessage",
                                "menuItem:share:timeline",
                                "menuItem:share:qq",
                                "menuItem:share:weiboApp",
                                "menuItem:share:appMessage",
                                "menuItem:copyUrl",
                                "menuItem:openWithQQBrowser",
                                "menuItem:openWithSafari",
                                "menuItem:share:brand",
                                "menuItem:share:QZone",
                                "menuItem:share:facebook",
                                "menuItem:favorite",
                                "menuItem:favorite",
                            ], // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有 menu 项见附录3
                        });
                        // alert("ready");
                        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
                    });
                    wx.error(function (res) {
                        this.tsRemark = JSON.stringify(res);
                        // alert(JSON.stringify(res), "error");
                        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
                    });
              }
            })
    },
    getMyPassenger() {
      let params = {}
      passenger.getMyPassenger(params).then(res => {
        if(res.data.code == 200) {
          this.myTx = res.data.data
        }
      })
    },
    formatCarNo(e) {
      const nBegin = e.substring(0,2)
      const nEnd = e.substring(e.length - 2, e.length)
      return nBegin + '***' + nEnd
    },
    goTo() {
      if(this.myInfo.types == '2' && this.myInfo.status == '2') {
        Dialog.alert({
            title: '认证提醒',
            message: '您的车主审核尚未通过，请耐心等待',
        }).then(() => {
        // on close
            // this.getUserinfoAscy()
        });
      } else {
        if(this.myInfoType == 1) {
          this.$router.push({
              name: 'ckfb-add',
              query: {
                isAdd: 'ADD'
              }
            })
        } else if(this.myInfoType == 2) {
          this.$router.push({
              name: 'czfb-add',
              query: {
                isAdd: 'ADD'
              }
            })
        }
      }
    },
    goMy() {
      this.$router.push({
            name: 'my'
          })
    },
    searchXc() {
      this.getPassengerTripList();
    },
    showTrip(e) {
      if(this.myInfo.types == '2' && this.myInfo.status == '2') {
        Dialog.alert({
            title: '认证提醒',
            message: '您的车主审核尚未通过，请耐心等待',
        }).then(() => {
        // on close
            // this.getUserinfoAscy()
        });
        return
      }
      this.$router.push({
        name: "trip-info",
        query: {
          id: e.id,
        },
      });
    },
    showPassengerInfo() {
      this.$router.push({
        name: "passenger-info"
      });
    },
    onConfirm() {
      this.$refs.item.toggle();
    },
    time2(data) {
      if (data) {
        if (data == this.dayjs().format("YYYY-MM-DD")) {
          return "今天";
        } else if (
          this.dayjs(data) > this.dayjs() &&
          this.dayjs(data) < this.dayjs().add(1, "day")
        ) {
          return "明天";
        } else {
          return this.dayjs(data).format("MM月DD日");
        }
      }
    },
    getPassengerTripList() {
      this.isShowLoading = true;
      let params = {
        page: 1,
        pageSize: 15,
        searchkey: this.items[this.active].text,
      };
      passenger.getPassengerTripList(params).then((res) => {
        this.sfcList = res.data.data;
        this.isShowLoading = false;
      });
    },
    getPassengerTripDistrictList() {
      this.isShowLoading = true;
      let params = {};
      passenger.getPassengerTripDistrictList(params).then((res) => {
        for(let i in res.data.data) {
          for(let j in this.items) {
            if(res.data.data[i].endDistrict.substring(6,9) == this.items[j].text) {
              this.items[j].badge = res.data.data[i].count
            }
          }
        }
        this.isShowLoading = false;
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    console.log("***************Enter**************");
    console.log("to", to);
    console.log("from", from);
    console.log("next", next);
    console.log(location.pathname);
    /* eslint-disable */
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        if(to.path !== location.pathname) {
          location.assign(to.fullPath);
        } else {
          next();
        }
    } else {
      next();
    }
  },
  // beforeEach(to, from, next) {
  //   console.log(to,"to");
  //   console.log(from,"from");
  //   console.log("leave");
  //   next();
  // }
};
</script>
<style scoped>
/deep/ .van-popup {
  background: none;
}
/deep/ .van-tree-select__nav {
  flex: 0.5;
}
/deep/ .van-info
{
  background: rgb(12, 151, 147);
}
/deep/ .van-sidebar-item--select::before {
  background-color: #42c6ff;
}
.top-box {width: 90%;margin-left: 5%;display: flex;align-items: center;justify-content: space-between;padding-top: 21px;z-index: 2;}
.top-box div p {font-size: 16px;color: #FAFAFA;line-height: 0;}
.riqi {display: flex;align-items: center;}
.riqi img {margin-right: 5px;}
.riqi span {font-size: 13px;color: #FAFAFA;}
.head-sty {width: 44px;height: 44px;border-radius: 50%;}
.dcx {
  position: absolute;
  right: 0;
  bottom: 150px;
  z-index: 999;
  background: rgb(12, 151, 147);
  color: #fff;
  padding: 5px 10px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.wdxc {
  position: absolute;
  z-index: 9999;
  color: #fff;
}
.list-item {
  width: 92%;
  margin-left: 4%;
  background: #fff;
  box-shadow: 0px 2px 10px -4px rgba(2, 66, 58, 0.2);
  border-radius: 8px;
  padding: 15px 15px 10px 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
}
.cklx {
  border: 1px solid #2d83e8;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  color: #2d83e8;
}
.bar-box {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}
.show-more {
  text-align: center;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  color: #2d83e8;
  margin-bottom: 10px;
  cursor: pointer;
}
.bar-item {
  flex: 1;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 14px;
  background: #fff;
  justify-content: center;
}
.bar-item img {
  width: 20px;
  margin-right: 5px;
}
</style>
